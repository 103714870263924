<template>
    <section class="call">
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getAlertTableData" :columns="tableColumn"
                   :query="formSearch" :height="720">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/rental-housing-management/sms-reminder/data";
    import {getAlertApi} from "@/api/alert";
    export default {
        name: "call",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                tableColumn,
                formSearch: {}
            };
        },
        components:{},
        created() {},
        methods: {
            getAlertTableData(params) {
                let {uuid} = this.tenantData;
                params.leasorUuid = uuid;
                return getAlertApi(params);
            },
        }
    }
</script>

<style scoped>

</style>