/**
 *
 * 简述：时间格式
 *
 * params:
 *   date: 时间 eg：new Date()
 *   fmt：格式  eg：yyyy-MM-dd HH:mm:ss  yyyy-MM-dd
 *
 * 使用
 *   eg: timeFormat(new Date(), "yyyy-MM-dd HH:mm:ss")
 *
 * */
export function timeFormat(date, fmt = "yyyy-MM-dd") {
    let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

/**
 * 2022/03/22 王江毅添加 格式化数字方法
 * 简述：数字格式(金额)
 *
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * dec_point：小数点符号
 * thousands_sep：千分位符号
 *
 *
 * */

export function numberFormat(number, decimals = 2, dec_point = ".", thousands_sep = ",") {

    number = (number + "").replace(/[^0-9+-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number,

        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === "undefined") ? "," : thousands_sep,
        dec = (typeof dec_point === "undefined") ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return "" + Math.floor(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : "" + Math.floor(n)).split(".");
    let re = /(-?\d+)(\d{3})/;
    //console.log(s)
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
}

// 2022/03/22 王江毅添加 分转元
export function ParseFloatFormat(number=0) {
    number = parseFloat(number);
    return parseFloat((number / 100).toFixed(2));
}
// 2022/03/22 王江毅添加 元转分
export function ParseIntFormat(number=0) {
    return parseInt((parseFloat(number) * 100).toFixed(0));
}