import request from "@http";

// 获取短信提醒列表
export function getAlertApi(params) {
    return request({
        method: "get",
        url: `/ops/gzf/sms/alert/page`,
        params,
    });
}

// 发送账单提醒
export function addBillSms(params) {
    return request({
        method: "get",
        url: `/ops/gzf/sms/alert/add/bill`,
        params,
    });
}